<template>
  <v-row>
    <v-col cols="12" class="py-0 px-0">
      <SearchBar
        show-advanced-search
        info="Advanced search uses all auditors related tables where several columns from every table is included in search. NOTE: Every searched results are cached for one hour!"
        search-label="Search Auditors"
        @onSearch="onSearch"
        :is-loading="loading"
      />
      <v-data-table
        :headers="headers"
        :items="data"
        :expanded.sync="expanded"
        :options.sync="options"
        hide-default-footer
        item-key="id"
        :items-per-page="sizes[size]"
        :show-expand="expandedHeaders.length > 0"
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table"
      >
        <template v-slot:item.name="{ item }">
          <span class="form-link" @click="open(item)">
             <v-icon
               color="primary"
               v-if="item.template_id !== null"
               x-small>fas fa-clone</v-icon>
            {{ item.name }}
          </span>
        </template>
        <template v-slot:item.description="{ item }">
          {{ item.description }}
        </template>
        <template v-slot:item.vendor="{ item }">
          <img
            :src="
              $vuetify.theme.dark
                ? vendorImage(item.vendor_id, true)
                : vendorImage(item.vendor_id, false)
            "
            :alt="item.vendor_id"
            class="vendor-img"
          />
        </template>
        <template v-slot:item.firewall="{ item }">
          <v-chip
            v-for="(firewall, index) in item.relatedFirewallName"
            :key="index"
            x-small
            color="neutral-4"
            class="mr-1 text-white"
          >
            {{ firewall }}
          </v-chip>
        </template>
        <template v-slot:item.active="{ item }">
          <v-icon :color="activeColor(item.active)" small>
            fas fa-circle
          </v-icon>
        </template>
        <template v-slot:item.severity="{ item }">
          <v-icon :class="severityClass(item.severity)"> mdi-view-grid </v-icon>
          <span class="ml-1">{{ severityName(item.severity) }}</span>
        </template>
        <template v-slot:item.xpath="{ item }">
          {{ item.xpath }}
        </template>
        <template
          v-slot:item.content="{ item }"
        >
          <template
            v-if="item.emptyContent"
          >
            WITHOUT CONTENT
          </template>
          <template
            v-else
          >
            {{
              getFormattedContent(
                item.content,
                item.maskedTags,
                item.mask,
                item.isMask
              )
            }}
          </template>
        </template>
        <template v-slot:item.conditions="{ item }">
          <div>
            <ul class="remove-bullet">
              <li v-for="(condition, index) in item.conditions" :key="index">
                <i
                  v-if="condition.includes('#')"
                  class="fas fa-times small-icon"
                ></i>
                <i v-else class="fas fa-check small-icon"></i>
                {{ condition }}
              </li>
            </ul>
          </div>
        </template>
        <template v-slot:item.groups="{ item }">
          <v-chip
            v-for="(group, index) in auditorGroups.filter((auditorGroup) =>
              item.groups.includes(auditorGroup.id)
            )"
            :key="index"
            x-small
            color="primary"
            class="elevation-2 synapsa-chip"
          >
            {{ group.name }}
          </v-chip>
        </template>

        <!-- DELETE COLUMN-->
        <template v-slot:item.delete="{ item }">
          <v-menu
            v-permission="['write auditorintegrity']"
            v-model="item.deleteMenu"
            :close-on-content-click="false"
            rounded
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn x-small icon color="bad-2" v-on="on" v-bind="attrs">
                <v-icon x-small>fas fa-trash-alt</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-list width="290">
                <v-list-item>
                  <v-list-item-content class="pb-0">
                    <v-list-item-title class="font-roboto-condensed-regular"
                      >Are you sure you want to delete this Integrity Check?
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-right mt-2">
                      <v-btn
                        rounded
                        text
                        color="tertiary"
                        small
                        class="text-capitalize synapsa-tab"
                        @click="cancelDelete(item)"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        rounded
                        text
                        color="bad-2"
                        small
                        class="text-capitalize synapsa-tab"
                        @click="deleteList(item)"
                      >
                        Delete
                      </v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </template>
        <!-- END DELETE COLUMN-->

        <!-- TEST INTEGRITY COLUMN-->
        <template v-slot:item.test="{ item }">
          <v-menu
            v-permission="['view auditorintegrity']"
            v-model="item.test"
            :close-on-content-click="false"
            rounded
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                color="primary"
                v-on="on"
                v-bind="attrs"
                @click="testIntegrity(item.id)"
              >
                <v-icon x-small>fas fa-play</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </template>
        <!-- END TEST INTEGRITY COLUMN-->

        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="expand-block"
            :class="data.indexOf(item) === data.length - 1 ? 'last' : ''"
          >
            <v-simple-table>
              <template v-slot:default>
                <tbody class="expanded">
                  <tr
                    v-for="(expandItem, key) in expandedHeaders"
                    :key="expandItem.value"
                    class="expand-tr"
                    :class="
                      data.indexOf(item) === data.length - 1 &&
                      key === expandedHeaders.length - 1
                        ? 'last'
                        : ''
                    "
                  >
                    <!--NAME-->
                    <td
                      v-if="expandItem.value === 'name'"
                      width="150"
                      class="pl-3"
                    >
                      Name:
                    </td>
                    <td v-if="expandItem.value === 'name'">
                      <span class="form-link" @click="open(item)">
                        <v-icon
                          color="primary"
                          v-if="item.template_id !== null"
                          x-small
                        >fas fa-clone</v-icon>
                        {{ item.name }}
                      </span>
                    </td>
                    <!--END NAME-->

                    <!--XPATH-->
                    <td
                      v-if="expandItem.value === 'xpath'"
                      width="150"
                      class="pl-3"
                    >
                      XPATH:
                    </td>
                    <td v-if="expandItem.value === 'xpath'">
                      {{ item.xpath }}
                    </td>
                    <!--END XPATH-->

                    <!--CONTENT-->
                    <td
                      v-if="expandItem.value === 'content'"
                      width="150"
                      class="pl-3"
                    >
                      Expected content:
                      <v-btn
                        v-permission="['view advancedmode']"
                        v-if="item.maskedTags && item.mask"
                        small
                        outlined
                        @click="onMaskClick(item, data.indexOf(item))"
                        class="btn-mask font-ubuntu-bold"
                      >
                        Mask
                        <v-icon class="mask" v-if="item.isMask">mdi-eye</v-icon
                        ><v-icon class="mask" v-else>mdi-eye-off</v-icon>
                      </v-btn>
                      <tr id="tr-fix">
                        <div class="btn-big">
                          <td>
                            <v-btn
                              class="text-capitalize mr-2 btn-data-formation"
                              :text="contentTabExpected !== 'format'"
                              :color="
                                contentTabExpected === 'format' ? 'primary' : ''
                              "
                              :outlined="contentTabExpected === 'format'"
                              rounded
                              @click="contentTabExpected = 'format'"
                            >
                              <span>Format</span>
                            </v-btn>
                          </td>
                          <td>
                            <v-btn
                              class="text-capitalize mr-2 btn-data-formation"
                              :text="contentTabExpected !== 'raw'"
                              :color="
                                contentTabExpected === 'raw' ? 'primary' : ''
                              "
                              :outlined="contentTabExpected === 'raw'"
                              rounded
                              @click="contentTabExpected = 'raw'"
                            >
                              <span>Raw</span>
                            </v-btn>
                          </td>
                        </div>
                      </tr>
                    </td>
                    <td
                      v-if="
                        expandItem.value === 'content' &&
                        contentTabExpected === 'format'
                      "
                    >
                      <template
                        v-if="item.emptyContent"
                      >
                        WITHOUT CONTENT
                      </template>
                      <v-expansion-panels
                        v-else
                      >
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            <template v-slot:actions>
                              <v-icon class="expansion-panel-header__icon"
                                >$expand</v-icon
                              >
                            </template>
                            <span class="expansion-panel-header__header"
                              >Formated data</span
                            >
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <xml-viewer
                              ref="viewer"
                              :xml="
                                getFormattedContent(
                                  item.content,
                                  item.maskedTags,
                                  item.mask,
                                  item.isMask
                                )
                              "
                              :class="$vuetify.theme.dark ? 'dark' : 'light'"
                            />
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </td>
                    <td
                      v-if="
                        expandItem.value === 'content' &&
                        contentTabExpected === 'raw'
                      "
                    >
                      <template
                        v-if="item.emptyContent"
                      >
                        WITHOUT CONTENT
                      </template>
                      <v-expansion-panels
                        v-else
                      >
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            <template v-slot:actions>
                              <v-icon class="expansion-panel-header__icon"
                                >$expand</v-icon
                              >
                            </template>
                            <span class="expansion-panel-header__header"
                              >Raw data</span
                            >
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            {{
                              getFormattedContent(
                                item.content,
                                item.maskedTags,
                                item.mask,
                                item.isMask
                              )
                            }}
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </td>
                    <!--END CONTENT-->

                    <!--VENDOR-->
                    <td
                      v-if="expandItem.value === 'vendor'"
                      width="150"
                      class="pl-3"
                    >
                      Vendor:
                    </td>
                    <td v-if="expandItem.value === 'vendor'">
                      <img
                        :src="vendorImage(item.vendor_id)"
                        :alt="item.vendor_id"
                        class="vendor-img"
                      />
                    </td>
                    <!--END VENDOR-->

                    <!--FIREWALLS-->
                    <td
                      v-if="expandItem.value === 'firewall'"
                      width="150"
                      class="pl-3"
                    >
                      Firewall / Group:
                    </td>
                    <td v-if="expandItem.value === 'firewall'">
                      <v-chip
                        v-for="(firewall, index) in item.relatedFirewallName"
                        :key="index"
                        x-small
                        color="neutral-4"
                        class="mr-1 text-white"
                      >
                        {{ firewall }}
                      </v-chip>
                    </td>
                    <!--END FIREWALLS-->

                    <!--DESCRIPTION-->
                    <td
                      v-if="expandItem.value === 'description'"
                      width="150"
                      class="pl-3"
                    >
                      Description:
                    </td>
                    <td v-if="expandItem.value === 'description'">
                      {{ item.description ? item.description : "-" }}
                    </td>
                    <!--END DESCRIPTION-->

                    <!--ACTIVE-->
                    <td
                      v-if="expandItem.value === 'active'"
                      width="150"
                      class="pl-3"
                    >
                      Active:
                    </td>
                    <td v-if="expandItem.value === 'active'">
                      <v-tooltip slot="append" right>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            :color="activeColor(item.active)"
                            small
                          >
                            fas fa-circle
                          </v-icon>
                        </template>
                        <span v-if="item.active">Active</span>
                        <span v-else>Inactive</span>
                      </v-tooltip>
                    </td>
                    <!--END ACTIVE-->

                    <!--SEVERITY-->
                    <td
                      v-if="expandItem.value === 'severity'"
                      width="150"
                      class="pl-3"
                    >
                      Severity:
                    </td>
                    <td v-if="expandItem.value === 'severity'">
                      <v-icon :class="severityClass(item.severity)" small>
                        mdi-view-grid
                      </v-icon>
                      <span class="ml-1">{{
                        severityName(item.severity)
                      }}</span>
                    </td>
                    <!--END SEVERITY-->

                    <!--CONDITIONS-->
                    <td
                      v-if="expandItem.value === 'conditions'"
                      width="150"
                      class="pl-3"
                    >
                      Cusom Conditions:
                    </td>
                    <td v-if="expandItem.value === 'conditions'">
                      <div>
                        <ul class="remove-bullet">
                          <li
                            v-for="(condition, index) in item.conditions"
                            :key="index"
                          >
                            <i
                              v-if="condition.includes('#')"
                              class="fas fa-times small-icon"
                            ></i>
                            <i v-else class="fas fa-check small-icon"></i>
                            {{ condition }}
                          </li>
                        </ul>
                      </div>
                    </td>
                    <!--END CONDITIONS-->

                    <!--GROUPS-->
                    <td
                      v-if="expandItem.value === 'groups'"
                      width="150"
                      class="pl-3"
                    >
                      Groups:
                    </td>
                    <td v-if="expandItem.value === 'groups'">
                      <v-chip
                        v-for="(
                          group, index
                        ) in auditorGroups.filter((auditorGroup) =>
                          item.groups.includes(auditorGroup.id)
                        )"
                        :key="index"
                        x-small
                        color="primary"
                        class="elevation-2 synapsa-chip"
                      >
                        {{ group.name }}
                      </v-chip>
                    </td>
                    <!--END GROUPS-->

                    <!--DELETE-->
                    <td
                      v-if="expandItem.value === 'delete'"
                      width="150"
                      class="pl-3"
                    >
                      Delete
                    </td>
                    <td v-if="expandItem.value === 'delete'">
                      <v-menu
                        v-permission="['write auditorintegrity']"
                        v-model="item.deleteMenu"
                        :close-on-content-click="false"
                        rounded
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            x-small
                            icon
                            color="bad-2"
                            v-on="on"
                            v-bind="attrs"
                          >
                            <v-icon x-small>fas fa-trash-alt</v-icon>
                          </v-btn>
                        </template>

                        <v-card>
                          <v-list width="350">
                            <v-list-item>
                              <v-list-item-content class="pb-0">
                                <v-list-item-title
                                  >Are you sure you want to delete this
                                  Integrity Check?
                                </v-list-item-title>
                                <v-list-item-subtitle class="text-right mt-2">
                                  <v-btn
                                    rounded
                                    text
                                    color="tertiary"
                                    small
                                    class="text-capitalize"
                                    @click="cancelDelete(item)"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    rounded
                                    text
                                    color="bad-2"
                                    small
                                    class="text-capitalize"
                                    @click="deleteList(item)"
                                  >
                                    Delete
                                  </v-btn>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </td>
                    <!--END DELETE-->

                    <!--TEST INTEGRITY-->
                    <td
                      v-if="expandItem.value === 'test'"
                      width="150"
                      class="pl-3"
                    >
                      Test integrity
                    </td>
                    <td v-if="expandItem.value === 'test'">
                      <v-menu
                        v-permission="['view auditorintegrity']"
                        v-model="item.test"
                        :close-on-content-click="false"
                        rounded
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            x-small
                            icon
                            color="primary"
                            v-on="on"
                            v-bind="attrs"
                            @click="testIntegrity(item.id)"
                          >
                            <v-icon x-small>fas fa-play</v-icon>
                          </v-btn>
                        </template>
                      </v-menu>
                    </td>
                    <!--END TEST INTEGRITY-->
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-if="data.length > 0" cols="6" class="py-0 px-0 synapsa-pagination">
      <v-btn-toggle v-model="size" tile group class="results px-1">
        <v-btn x-small class="result" rounded> 5 </v-btn>

        <v-btn x-small class="result" rounded> 10 </v-btn>

        <v-btn x-small class="result" rounded> 25 </v-btn>

        <v-btn x-small class="result" rounded> 50 </v-btn>

        <v-btn x-small class="result" rounded> 100 </v-btn>

        <v-btn x-small class="result" rounded> 200 </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col
      v-if="data.length > 0"
      cols="6"
      class="text-right py-0 px-0 synapsa-pagination"
      :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
    >
      <v-pagination
        v-model="page"
        :length="pageTotalCount"
        :total-visible="7"
        circle
        class="d-inline-block pagination px-1 py-1"
        :class="page > 99 ? 'over100' : 'less100'"
      />
    </v-col>
    <test-integrity
      v-if="!loading"
      @onClose="showTestIntegrityModal = false"
      :id="testIntegrityId"
      :show="showTestIntegrityModal"
    />
  </v-row>
</template>

<script>
import permission from "@/directive/permission/permission.js";
import SearchBar from "@/components/SearchBar";
import TestIntegrity from "@/layout/FormSidebar/auditorintegrity/testIntegrity";
import { getFormattedXml, maskTags } from "@/utils/auditor";
import XmlViewer from "@/plugins/xmlViewer";

export default {
  directives: { permission },
  components: {
    SearchBar,
    TestIntegrity,
    XmlViewer,
  },
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    expandedHeaders: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    auditorGroups: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tablePage: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tablePageTotalCount: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tableSize: {
      type: Number,
      default: function () {
        return 1;
      },
    },
  },
  data() {
    return {
      contentTabExpected: "format",
      expanded: [],
      options: {},
      location: "",

      page: 1,
      pageTotalCount: 1,
      size: 1,
      order: null,
      by: null,

      sizes: [5, 10, 25, 50, 100, 200],
      deleteMenu: false,

      showTestIntegrityModal: false,
      testIntegrityId: undefined,
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    auditorIntegrityTable: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          const self = this;
          this.headers = [];
          newValue.headers.forEach(function (header) {
            if (header.visible) {
              self.headers.push(header);
            }
          });
          this.expanded = [];
          newValue.expanded.forEach(function (item) {
            if (item.visible) {
              self.expanded.push(item);
            }
          });
        }
      },
    },
    tablePage: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.page = newValue;
      },
    },
    tablePageTotalCount: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pageTotalCount = newValue;
      },
    },
    tableSize: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.size = this.sizes.indexOf(newValue);
      },
    },
    page: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue !== this.tablePage) {
          this.options.page = newValue;
          this.$emit("change", { page: newValue, size: this.sizes[this.size] });
        }
      },
    },
    size: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue !== this.sizes.indexOf(this.tableSize)) {
          this.options.itemsPerPage = this.sizes[newValue];
          this.$emit("change", { page: this.page, size: this.sizes[newValue] });
        }
      },
    },
    expandedHeaders: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue.length === 0) {
          this.expanded = [];
        }
      },
    },
    options: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (
          newValue.sortBy &&
          newValue.sortDesc &&
          newValue.sortBy.length > 0 &&
          newValue.sortDesc.length > 0
        ) {
          const order = newValue.sortDesc[0] ? "desc" : "asc";
          const by = newValue.sortBy[0];
          this.$emit("change", {
            page: this.page,
            size: this.sizes[this.size],
            order: order,
            orderBy: by,
          });
        }
      },
    },
    changeInAddForm: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue === "auditorintegrity") {
          this.loadData(this.page, this.size);
        }
        this.changeInAddForm = "";
      },
    },
  },
  created() {
    this.location = window.location.origin;
  },
  methods: {
    testIntegrity(integrityId) {
      this.showTestIntegrityModal = true;
      this.testIntegrityId = integrityId;
    },
    vendorImage(vendor, dark) {
      if (vendor === 1) {
        return dark
          ? this.location + "/images/vendors/paloalto_white.png"
          : this.location + "/images/vendors/paloalto.png";
      } else if (vendor === 2) {
        return dark
          ? this.location + "/images/vendors/fortinet_white.png"
          : this.location + "/images/vendors/fortinet.png";
      } else if (vendor === 3) {
        return dark
          ? this.location + "/images/vendors/checkpoint_white.png"
          : this.location + "/images/vendors/checkpoint.png";
      } else if (vendor === 4) {
        return this.location + "/images/vendors/sophos.png";
      } else {
        return null;
      }
    },
    getFormattedContent(content, maskedTags, mask, isMask) {
      if (content && maskedTags && mask && isMask) {
        return getFormattedXml(maskTags(content, maskedTags, mask));
      }
      return getFormattedXml(content);
    },
    activeColor(status) {
      if (status) {
        return "#87d05a";
      } else {
        return "#e53355";
      }
    },
    severityName(status) {
      if (status === 1) {
        return "Low";
      } else if (status === 2) {
        return "Medium";
      } else if (status === 3) {
        return "High";
      } else {
        return "Critical";
      }
    },
    severityColor(status) {
      if (status === 1) {
        return "neutral-3";
      } else if (status === 2) {
        return "good-3";
      } else if (status === 3) {
        return "medium-3";
      } else {
        return "bad-3";
      }
    },
    severityClass(status) {
      if (status === 1) {
        return "signal-icon--low";
      } else if (status === 2) {
        return "signal-icon--medium";
      } else if (status === 3) {
        return "signal-icon--high";
      } else {
        return "signal-icon--critical";
      }
    },
    open(item) {
      this.idAddForm = item.id;
      this.keywordAddForm = "auditorintegrity";
    },
    cancelDelete(item) {
      this.$emit("cancelDelete", item);
    },
    deleteList(item) {
      this.$emit("deleteList", item);
    },
    onSearch(event) {
      this.$emit("onSearch", event);
    },
    onMaskClick(item, index) {
      this.$emit("onMaskClick", { data: item, index });
    },
  },
};
</script>
<style lang="scss" scoped>
.remove-bullet {
  list-style-type: none;
  padding-left: 0;

  .small-icon {
    font-weight: 600;
    font-size: 8px;
  }
}
.btn-data-formation {
  height: 24px !important;
  padding: 0 8px !important;
  min-width: 48px !important;
  border: none;
}
.synapsa-table.theme--light ::v-deep {
  #tr-fix {
    background-color: #f7f7f7 !important;
    td {
      height: 20px !important;
    }
  }
  #tr-fix:hover {
    background-color: #f7f7f7 !important;
  }
}
.synapsa-table.theme--dark ::v-deep {
  #tr-fix {
    background-color: #5e5e6099 !important;
    td {
      height: 20px !important;
    }
  }
  #tr-fix:hover {
    background-color: #5e5e6099 !important;
  }
}
.btn-big {
  border: 1px solid #69be28;
  border-radius: 30px;
  margin: 4px 0 4px -6px;
  span {
    font-size: 0.75rem !important;
    font-family: UbuntuBold, sans-serif !important;
  }
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #f7f7f7;
}
.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: transparent;
}
.v-expansion-panel::before {
  box-shadow: none;
}
.v-expansion-panel-header__icon {
  margin-bottom: 6px !important;
}
.expansion-panel-header__icon {
  order: 0;
}
.expansion-panel-header__header {
  order: 1;
}
</style>
