<template>
  <v-container fluid>
    <auditor-integrity-table
      :headers="headers"
      :expanded-headers="expanded"
      :auditor-groups="auditorGroups"
      :data="data"
      :loading="loading"
      :table-page="page"
      :table-page-total-count="pageTotalCount"
      :table-size="size"
      @change="loadData($event.page, $event.size, $event.order, $event.orderBy)"
      @cancelDelete="cancelDelete($event)"
      @deleteList="deleteList($event)"
      @onSearch="onSearch"
      @onMaskClick="onMaskClick($event)"
    />
  </v-container>
</template>

<script>
import auditorIntegrityTable from "./integrity/table";
import { Errors } from "@/utils/error";
import { getAuditors, deleteAuditor } from "@/api/auditor/integrity";
import {getAuditorGroups} from "@/api/auditor/group";

export default {
  components: {
    auditorIntegrityTable,
  },
  data() {
    return {
      headers: [],
      expanded: [],
      data: [],
      auditorGroups: [],
      loading: true,
      search: undefined,

      page: 1,
      pageTotalCount: 1,
      size: 25,
      sizeTotalCount: 0,
    };
  },
  computed: {
    changeInAddForm: {
      get() {
        return this.$store.state.settings.changeInAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "changeInAddForm",
          value: val,
        });
      },
    },
    auditorIntegrityTable: {
      get() {
        return this.$store.state.tables.auditorIntegrityTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "auditorIntegrityTable",
          value: val,
        });
      },
    },
  },
  watch: {
    changeInAddForm: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue === "integrity") {
          this.loadData(this.page, this.size);
        }
        this.changeInAddForm = "";
      },
    },
    auditorIntegrityTable: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          const self = this;
          this.headers = [];
          newValue.headers.forEach(function (header) {
            if (header.visible) {
              self.headers.push(header);
            }
          });
          this.expanded = [];
          newValue.expanded.forEach(function (item) {
            if (item.visible) {
              self.expanded.push(item);
            }
          });
        }
      },
    },
  },
  created() {
    this.loadData(this.page, this.size);
    this.loadAuditorGroups();
  },
  methods: {
    loadData(page, size) {
      this.loading = true;
      let newPage = page;
      if (size !== this.size) {
        newPage = 1;
      }
      getAuditors(newPage, size, this.search)
        .then((response) => {
          this.loading = false;
          this.data = response.data;
          this.data.forEach(function (value) {
            value.deleteMenu = false;
            value.isMask = true;
          });
          this.pageTotalCount = response.last_page;
          this.page = response.current_page;
          this.size = parseInt(response.per_page);
          this.sizeTotalCount = response.total;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
    loadAuditorGroups() {
      getAuditorGroups()
          .then((response) => {
            this.auditorGroups = response;
          })
          .catch((e) => {
            Errors.show(e);
          });
    },
    cancelDelete(item) {
      const data = [];
      this.data.forEach(function (value) {
        if (item.id === value.id) {
          value.deleteMenu = false;
        }
        data.push(value);
      });
      this.data = [];
      this.$nextTick(() => {
        this.data = data;
      });
    },
    deleteList(item) {
      this.loading = true;
      const data = [];
      this.data.forEach(function (value) {
        if (item.id === value.id) {
          value.deleteMenu = false;
        }
        data.push(value);
      });
      this.data = [];
      this.$nextTick(() => {
        this.data = data;
      });
      deleteAuditor(item.id)
        .then(() => {
          this.$toast.open({
            message: "Auditor successfully deleted!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          if (this.data.length === 1 && this.page !== 1) {
            this.loadData(this.page - 1, this.size, this.activeTab);
          } else {
            this.loadData(this.page, this.size, this.activeTab);
          }
        })
        .catch((e) => {
          Errors.show(e);
          const data = [];
          this.data.forEach(function (value) {
            data.push(value);
          });
          this.data = [];
          this.$nextTick(() => {
            this.data = data;
            this.loading = false;
          });
        });
    },
    onSearch(event) {
      this.search = event;
      this.page = 1;
      this.loadData(this.page, this.size);
    },
    onMaskClick(event) {
      const item = event.data;
      const index = event.index;
      item.isMask = !item.isMask;
      this.$set(this.data, index, item);
    }
  },
};
</script>
